<template>
  <el-container>
    <el-header style="height: 52px; padding: 0">
      <Nav
        :title="PLATFORM_NAME"
        :is-logged="isLogged"
        :user="user"
        :options="navOptions"
        @onLogin="onLogin"
        @onLogout="onLogout"
      />
    </el-header>
    <el-container>
      <el-aside width="200px">
        <Sidebar
          :top="52"
          :menus="menus"
          :routePropUrls="routePropUrls"
          footerInfo="@新奥智能装备"
          router
        />
      </el-aside>
      <el-main class="main">
        <router-view />
        <!-- <div>个人中11心</div> -->
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import Nav from "enn-navigation-bar";
import Sidebar from "enn-sidebar";
import { getToken, setToken, getUser, setUser } from "@/utils/info";

export default {
  components: {
    Nav,
    Sidebar,
  },
  data() {
    return {
      PLATFORM_NAME: process.env.VUE_APP_PLATFORM_NAME,
      navOptions: [
        // {
        //   label: "个人中心",
        //   name: "personal",
        // },
      ],
      routePropUrls: {
        "/workflow/process": "/workflow",
      },
    };
  },
  computed: {
    user() {
      return getUser();
    },
    menus() {
      const user = getUser();
      
      const menus = [
        {
          icon: "icon-shujuzonglan",
          index: "/",
          title: "首页",
        },
        {
          icon: "icon-chanpinmoxing",
          index: "/workflow",
          title: "制程管理",
        },

        {
          icon: "icon-yingyongguanli1",
          index: "/product",
          title: "生产管理",
          subs: [
            {
              index: "/product",
              title: "产品管理",
            },
            {
              index: "/order",
              title: "订单管理",
            },
            // {
            //   index: "/personal",
            //   title: "个人中心",
            // },
          ],
        },
        {
          icon: "icon-a-shebeizhongduanguanli",
          index: "/sn",
          title: "条码管理",
          subs: [
            {
              index: "/sn",
              title: "SN管理",
            },
            
            {
              index: "/gweui",
              title: "GWEUI管理",
            },
            {
              index: "/mac",
              title: "MAC管理",
            },
            {
              index: "/macRequisition",
              title: "MAC资源申请",
            },
          ],
        },
        {
          icon: "icon-jingxiangliebiao",
          index: "/factory",
          title: "车间管理",
        },
        {
          icon: "icon-yonghuguanli",
          index: "/user",
          title: "用户管理",
        },
        {
          icon: "icon-gongzuokongjian",
          index: "/log",
          title: "日志查询",
          subs: [
            {
              index: "/log",
              title: "日志",
            },
            {
              index: "/device",
              title: "设备",
            },
          ],
        },
      ];
      if (user.roleType === 'super') {
        // menus.unshift({
        //   icon: "icon-shujuzonglan",
        //   index: "/",
        //   title: "首页",
        // });
        menus.push({
          icon: "icon-Ticket",
          index: "/opLog",
          title: "操作记录",
        });
      }
      return menus;
    },
    isLogged() {
      return !!getToken();
    },
  },
  methods: {
    onLogin() {
      this.$router.push({ name: "login" });
    },
    onLogout() {
      setToken("");
      setUser();
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style>
.main {
  height: calc(100vh - 52px);
  padding: 16px 20px 32px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.main,
.page-box {
  background-color: #f0f2f5;
}
.center-container {
  width: 100%;
  overflow-y: auto;
}
.center-container .main {
  max-width: 1200px;
  margin: 0 auto;
  overflow: unset;
}
/* el-card 去除边框 */
div.grid-card.el-card {
  padding-top: 8px;
  border: none;
}
.grid-card .el-card__header {
  padding: 12px 20px 0;
  border: none;
}
.info-title {
  color: #333333;
  font-size: 18px;
  line-height: 30px;
}
.grid-card .el-card__body {
  padding: 12px 22px 24px;
}
</style>
